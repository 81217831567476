import { graphql } from 'gatsby';
import React from 'react';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Resource from '../components/Resource';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import { getKeyedResources } from '../utils/resource-helper';

const textOverlay = (
  <div className="students__text_overlay_container">
    <Container fullWidth>
      {/* <div className="students__text_overlay_subheader">STUDENTS</div> */}
      <div className="students__text_overlay_info">Let’s explore!</div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    Everyday life is a never-ending source of raw data full of deep insights
    into our world.
  </span>
);

const Students = ({ data }) => {
  const {
    studentsEnvironmentalJustice,
    studentsAccessToHealth,
    studentsSocialMediaAndMisinformation,
    studentsWomenGenderEquity,
    studentsUsingCensusSnapshot,
    studentsUnderstandingElectionCycle,
    studentsDataDuringHolidays,
    studentsBigDataBigGame,
    studentsDataGetsGreen,
    dataInMyDay,
    clientSolutionsExecutiveRec,
    clientSolutionsRec,
  } = getKeyedResources(data);

  return (
    <Layout title="Students">
      <Hero
        mediaSource="img-lead-triangle-students.png"
        textOverlay={textOverlay}
      />
      <Section className="students__callout-section section--gray">
        <Container fullWidth>
          <Row className="students__callout-row">
            <Column largeOffset={1} large={10}>
              <Callout
                className="students__callout"
                containerClassName="students__callout-container"
              >
                See your ideas come to life through a data lens
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={10}>
              <p className="text-sans-serif-large mb-2">
                Got 15 minutes to go on a <strong>virtual investigation</strong>
                ? Or 30 minutes to jump into a{' '}
                <strong>hands-on activity</strong>? You don’t need a degree to
                dig into the infinite possibilities of data – just take another
                look at your own world! Discover how each one of us is
                continuously producing a digital treasure trove of data that
                reflects our choices and experiences.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Quicklinks
                links={[
                  {
                    target: '#student-interactive',
                    label: 'Student interactive',
                  },
                  {
                    target: '#activities',
                    label: 'Activities',
                  },
                  {
                    target: '#explore-careers',
                    label: 'Careers',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section id="student-interactive" className={'section--gray'}>
        <Container>
          <Row className={'mb-0'}>
            <Column size={12} className="students__section-header">
              Student interactive
            </Column>
          </Row>
          <Row className={'students__section--wrapper pt-2'}>
            <Row>
              <Column size={12}>
                <Resource
                  title={dataInMyDay.title}
                  audience={dataInMyDay.audience}
                  duration={dataInMyDay.duration}
                  description={dataInMyDay.description}
                  img={dataInMyDay.img}
                  actions={dataInMyDay.actions}
                  horizontal={true}
                />
              </Column>
            </Row>
          </Row>
        </Container>
      </Section>
      <Section id="activities">
        <Container>
          <Row>
            <Column size={9}>
              <h2>Ready-to-use activities</h2>
              <p className="text-sans-serif">
                Do data YOUR WAY with easy-to-use activities designed for
                students in Grades 6–8. In 45 minutes or less, each activity
                introduces a game-changing way of reimagining your world with
                data!
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={studentsEnvironmentalJustice.tophat}
                title={studentsEnvironmentalJustice.title}
                subject={studentsEnvironmentalJustice.subject}
                duration={studentsEnvironmentalJustice.duration}
                className={studentsEnvironmentalJustice.className}
                audience={studentsEnvironmentalJustice.audience}
                description={studentsEnvironmentalJustice.description}
                img={studentsEnvironmentalJustice.img}
                actions={studentsEnvironmentalJustice.actions}
                ribbon={studentsEnvironmentalJustice.ribbon}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsAccessToHealth.tophat}
                title={studentsAccessToHealth.title}
                subject={studentsAccessToHealth.subject}
                duration={studentsAccessToHealth.duration}
                className={studentsAccessToHealth.className}
                audience={studentsAccessToHealth.audience}
                description={studentsAccessToHealth.description}
                img={studentsAccessToHealth.img}
                actions={studentsAccessToHealth.actions}
                ribbon={studentsAccessToHealth.ribbon}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsSocialMediaAndMisinformation.tophat}
                title={studentsSocialMediaAndMisinformation.title}
                subject={studentsSocialMediaAndMisinformation.subject}
                duration={studentsSocialMediaAndMisinformation.duration}
                className={studentsSocialMediaAndMisinformation.className}
                audience={studentsSocialMediaAndMisinformation.audience}
                description={studentsSocialMediaAndMisinformation.description}
                img={studentsSocialMediaAndMisinformation.img}
                actions={studentsSocialMediaAndMisinformation.actions}
                ribbon={studentsSocialMediaAndMisinformation.ribbon}
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={studentsWomenGenderEquity.tophat}
                title={studentsWomenGenderEquity.title}
                subject={studentsWomenGenderEquity.subject}
                duration={studentsWomenGenderEquity.duration}
                className={studentsWomenGenderEquity.className}
                audience={studentsWomenGenderEquity.audience}
                description={studentsWomenGenderEquity.description}
                img={studentsWomenGenderEquity.img}
                actions={studentsWomenGenderEquity.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsUsingCensusSnapshot.tophat}
                title={studentsUsingCensusSnapshot.title}
                subject={studentsUsingCensusSnapshot.subject}
                duration={studentsUsingCensusSnapshot.duration}
                className={studentsUsingCensusSnapshot.className}
                audience={studentsUsingCensusSnapshot.audience}
                description={studentsUsingCensusSnapshot.description}
                img={studentsUsingCensusSnapshot.img}
                actions={studentsUsingCensusSnapshot.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsUnderstandingElectionCycle.tophat}
                title={studentsUnderstandingElectionCycle.title}
                subject={studentsUnderstandingElectionCycle.subject}
                duration={studentsUnderstandingElectionCycle.duration}
                className={studentsUnderstandingElectionCycle.className}
                audience={studentsUnderstandingElectionCycle.audience}
                description={studentsUnderstandingElectionCycle.description}
                img={studentsUnderstandingElectionCycle.img}
                actions={studentsUnderstandingElectionCycle.actions}
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={studentsDataDuringHolidays.tophat}
                title={studentsDataDuringHolidays.title}
                subject={studentsDataDuringHolidays.subject}
                duration={studentsDataDuringHolidays.duration}
                className={studentsDataDuringHolidays.className}
                audience={studentsDataDuringHolidays.audience}
                description={studentsDataDuringHolidays.description}
                img={studentsDataDuringHolidays.img}
                actions={studentsDataDuringHolidays.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsBigDataBigGame.tophat}
                title={studentsBigDataBigGame.title}
                subject={studentsBigDataBigGame.subject}
                duration={studentsBigDataBigGame.duration}
                className={studentsBigDataBigGame.className}
                audience={studentsBigDataBigGame.audience}
                description={studentsBigDataBigGame.description}
                img={studentsBigDataBigGame.img}
                actions={studentsBigDataBigGame.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={studentsDataGetsGreen.tophat}
                title={studentsDataGetsGreen.title}
                subject={studentsDataGetsGreen.subject}
                duration={studentsDataGetsGreen.duration}
                className={studentsDataGetsGreen.className}
                audience={studentsDataGetsGreen.audience}
                description={studentsDataGetsGreen.description}
                img={studentsDataGetsGreen.img}
                actions={studentsDataGetsGreen.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={'section--gray'}>
        <Container>
          <Row>
            <Column size={9}>
              <h2 id="explore-careers">Explore careers in data</h2>
              <p className="text-sans-serif">
                Careers in data can be found in just about any industry you can
                think of. From creative thinkers to business leaders to
                number-crunchers, everyone can find a career they care about
                using the infinite potential of data.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              <Resource
                title={clientSolutionsExecutiveRec.title}
                className={clientSolutionsExecutiveRec.className}
                description={clientSolutionsExecutiveRec.description}
                img={clientSolutionsExecutiveRec.img}
                actions={clientSolutionsExecutiveRec.actions}
                linkResource
              />
            </Column>
            <Column size={6}>
              <Resource
                title={clientSolutionsRec.title}
                className={clientSolutionsRec.className}
                description={clientSolutionsRec.description}
                img={clientSolutionsRec.img}
                actions={clientSolutionsRec.actions}
                linkResource
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* <SurveyCallout /> */}
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-students@2x.jpg"
          content={subfooterContent}
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query StudentResources {
    allResourcesJson(filter: { pages: { in: "students" } }) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          ribbon
          actions {
            label
            metadata
            link
            module
            moduleName
            download
            noIcon
            asLink
            disabled
            segmentData {
              clickLabel
              clickType
              format
            }
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default Students;
